<!--表单页面拷贝开始-->
<template>
  <div>
    <form-components @submitForm="submitForm" @cancelForm="cancelForm" :formData="tripCar" :rules="tripCarRules"
      ref="form">
      <template v-slot:content>
        <div class="head"><i></i>行驶证</div>
        <div class="uploaderList">
          <div class="uploader">
            <el-form-item label="上传行驶证" label-width="150" prop="driverLicenseImg">
              <div v-if="tripCar.driverLicenseImg" class="avatar">
                <img @click="
                  driverLicenseImgShow(
                    preImage.driverLicenseImg || tripCar.driverLicenseImg
                  )
                " :src="preImage.driverLicenseImg || tripCar.driverLicenseImg" />
                <span class="el-upload-list__item-delete" @click="handleRemove('driverLicenseImg')">
                  <i class="el-icon-delete"></i>
                </span>
              </div>
              <el-upload v-else class="avatar-uploader" :action="uploadPath" :show-file-list="false"
                :on-success="handleAvatarSuccess.bind(this, 'tripCar')" :before-upload="beforeAvatarUpload">
                <i class="el-icon-plus avatar-uploader-icon"></i>
                <span>上传</span>
              </el-upload>
            </el-form-item>
          </div>
          <div class="uploader">
            <el-form-item label="上传营运证" prop="operationCertificateImg">
              <div v-if="tripCar.operationCertificateImg" class="avatar">
                <img @click="
                  driverLicenseImgShow(
                    preImage.driverLicenseImg ||
                    tripCar.operationCertificateImg
                  )
                " :src="
  preImage.operationCertificateImg ||
  tripCar.operationCertificateImg
" />
                <span class="el-upload-list__item-delete" @click="handleRemove('operationCertificateImg')">
                  <i class="el-icon-delete"></i>
                </span>
              </div>
              <el-upload v-else class="avatar-uploader" :action="uploadPath" :show-file-list="false" :on-success="
                handleAvatarSuccess.bind(this, 'operationCertificateImg')
              " :before-upload="beforeAvatarUpload">
                <i class="el-icon-plus avatar-uploader-icon"></i>
                <span>上传</span>
              </el-upload>
            </el-form-item>
          </div>
        </div>

        <el-row>
          <el-col :span="5">
            <el-form-item label="车牌号" prop="carNum">
              <el-input v-model.trim="tripCar.carNum" maxlength="8" placeholder="请输入" />
            </el-form-item>
          </el-col>
          <el-col :span="5" :offset="1">
            <el-form-item label="所属公司" class="required">
              <el-input v-model="$store.state.user.companyName" disabled />
            </el-form-item>
          </el-col>
          <el-col :span="5" :offset="1">
            <el-form-item label="所属车队" prop="motorcadeNo">
              <el-select v-model="tripCar.motorcadeNo" placeholder="请选择"
                :disabled="$store.state.user.type == 'motorcade'">
                <el-option v-for="item in motorCadeList" :key="item.no" :label="item.name" :value="item.no">
                </el-option>
              </el-select>
              <!-- <el-input v-model="tripCar.motorcadeNo" />  -->
            </el-form-item>
          </el-col>
          <el-col :span="5" :offset="1">
            <el-form-item label="车辆类型" prop="type">
              <el-input v-model.trim="tripCar.type" maxlength="40" placeholder="请输入" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="5">
            <el-form-item label="所有人" prop="belong">
              <el-input v-model.trim="tripCar.belong" maxlength="40" placeholder="请输入" />
            </el-form-item>
          </el-col>
          <el-col :span="5" :offset="1">
            <el-form-item label="地址" prop="address">
              <el-input v-model.trim="tripCar.address" maxlength="200" placeholder="请输入" />
            </el-form-item>
          </el-col>
          <el-col :span="5" :offset="1">
            <el-form-item label="品牌型号" prop="brand">
              <el-input v-model.trim="tripCar.brand" maxlength="40" placeholder="请输入" />
            </el-form-item>
          </el-col>
          <el-col :span="5" :offset="1">
            <el-form-item label="车辆识别代码" prop="identificationCode">
              <el-input v-model.trim="tripCar.identificationCode" maxlength="17" placeholder="请输入" />
            </el-form-item>
          </el-col>

        </el-row>
        <el-row>
          <el-col :span="5">
            <el-form-item label="发动机号" prop="engineCode">
              <el-input v-model.trim="tripCar.engineCode" maxlength="40" placeholder="请输入" />
            </el-form-item>
          </el-col>
          <el-col :span="5" :offset="1">
            <el-form-item label="注册日期" prop="registerTime">
              <el-date-picker type="date" v-model="tripCar.registerTime" value-format="timestamp"
                :picker-options="registerTimeOptions" placeholder="选择日期">
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="5" :offset="1">
            <el-form-item label="最大载客数(人)" prop="maxNum">
              <el-input v-model="tripCar.maxNum" oninput="value=value.replace(/[^0-9]/g,'')" maxlength="10"
                placeholder="请输入" />
            </el-form-item>
          </el-col>

        </el-row>
      </template>
    </form-components>
    <el-dialog :visible.sync="dialogVisible">
      <img width="100%" :src="dialogImageUrl" alt="" />
    </el-dialog>
  </div>
</template>
  
  <script>
import FormComponents from "@/components/form/FormComponents";
import TripCarApi from "@/api/TripCarApi";
import TripDictionaryApi from "@/api/TripDictionaryApi";
import { navTo, queryParam } from "@/utils/NavigatorUtils";
import { uploaderPath } from "@/utils/UploaderUtils";
import TripMotorCadeApi from "@/api/TripMotorCadeApi";
import ocrApi from "@/api/ocrApi";
import { mapMutations } from "vuex";
export default {
  name: "SaveTripCarPage",
  props: {
    msg: String,
  },
  components: {
    FormComponents,
  },
  data() {
    let that = this;
    return {
      imageUrl: "",
      dialogVisible: false,
      dialogImageUrl: "",
      tripCar: {
        id: "", //
        carNum: "", //车牌号
        type: "", //车辆类型
        belong: "", //所有人
        address: "", //地址
        brand: "", //品牌型号
        identificationCode: "", //车辆识别号
        engineCode: "", //发动机识别号
        registerTime: "", //注册时间
        companyNo: "", //所属公司
        motorcadeNo: "", //车队no
        maxNum: 4, //最大载客数
        createTime: "", //创建时间
        updateTime: "", //更新时间
        operationCertificateImg: "", //营运证地址
        driverLicenseImg: "", //行驶证地址
        isDelete: "", //删除标记位
        createUser: "", //创建人
        updateUser: "", //更新人
        driverList: [],
      },
      preImage: {
        operationCertificateImg: "", //营运证地址
        driverLicenseImg: "", //行驶证地址
        imgPath: [null, null],
      },
      motorCadeList: null, //车队列表
      tripCarRules: {
        // driverLicenseImg: [
        //   { required: true, message: "请上传行驶证", trigger: "change" },
        // ],
        // operationCertificateImg: [
        //   { required: true, message: "请上传营运证", trigger: "change" },
        // ],
        carNum: [
          { required: true, message: "车牌号不能为空", trigger: "blur" },
          {
            pattern:
              /[京津冀晋蒙辽吉黑沪苏浙皖闽赣鲁豫鄂湘粤桂琼川贵云渝藏陕甘青宁新使]{1}[a-zA-Z]{1}[a-zA-Z0-9]{5,6}/,
            message: "车牌号格式错误",
            trigger: "blur",
          },
        ],
        // type: [
        //   { required: true, message: "车辆类型不能为空", trigger: "blur" },
        //   { min: 2, max: 40, message: "长度应为2-40字符", trigger: "blur" },
        // ],
        // belong: [
        //   { required: true, message: "所有人不能为空", trigger: "blur" },
        //   { min: 2, max: 40, message: "长度应为2-40字符", trigger: "blur" },
        // ],
        // address: [
        //   { required: true, message: "地址不能为空", trigger: "blur" },
        //   { min: 2, max: 200, message: "长度应为2-200字符", trigger: "blur" },
        // ],
        // brand: [
        //   { required: true, message: "品牌型号不能为空", trigger: "blur" },
        //   { min: 2, max: 40, message: "长度应为2-40字符", trigger: "blur" },
        // ],
        // identificationCode: [
        //   { required: true, message: "车辆识别码不能为空", trigger: "blur" },
        //   {
        //     pattern: /[A-HJ-NPR-Z\d]{17}/,
        //     message: "车辆识别码格式错误",
        //     trigger: "blur",
        //   },
        // ],
        // engineCode: [
        //   { required: true, message: "发动机号不能为空", trigger: "blur" },
        //   {
        //     pattern: /^[A-Za-z0-9\-]*$/,
        //     message: "车辆识别码格式错误",
        //     trigger: "blur",
        //   },
        // ],
        // registerTime: [
        //   { required: true, message: "注册日期不能为空", trigger: "blur" },
        // ],
        motorcadeNo: [
          { required: true, message: "所属车队不能为空", trigger: "change" },
        ],
        // maxNum: [
        //   { required: true, message: "最大载客数不能为空", trigger: "blur" },
        //   {
        //     validator: (rule, value, callback) => {
        //       let num = parseInt(value);
        //       if (num && num > 0 && num < 11) {
        //         callback();
        //       } else {
        //         callback(new Error("最大载客数不正确"));
        //       }
        //     },
        //     trigger: "blur",
        //   },
        // ],
      },
      uploadPath: uploaderPath(),
      birthdayOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
      },
      firstApplyTimeOptions: {
        disabledDate(time) {
          let flag = false;
          if (
            that.currentselectDriverIndex >= 0 &&
            that.driverList[that.currentselectDriverIndex].birthday
          ) {
            flag =
              time.getTime() <
              that.driverList[that.currentselectDriverIndex].birthday;
          }
          return time.getTime() > Date.now() || flag;
        },
      },
      validateTimeOptions: {
        disabledDate(time) {
          return new Date(time).getTime() < Date.now();
        },
      },
      registerTimeOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
      },
    };
  },
  async created() {
    await this.loadMotorCadeList();
    if (this.$route.params.id) {
      this.tripCar = this.$route.params;
    }
  },
  methods: {
    ...mapMutations({
      close: "mutationCloseTag",
    }),
    async submitForm() {
      // let validState = true;
      // for (let i = 0; i < this.driverList.length; i++) {
      //   let ref = this.$refs["driverForm" + i][0];
      //   let valid = await ref.validate().catch((err) => {
      //     return err;
      //   });
      //   if (!valid) {
      //     validState = false;
      //     return;
      //   }

      //   if (this.driverList[i].isLongTermValid)
      //     this.driverList[i].validateTime = "长期";
      // }
      // if (!validState) return;

      // this.tripCar.driverList = this.driverList;
      // console.log(this.tripCar);
      this.tripCar.id > 0 ? this.updateTripCarById() : this.addTripCar();
    },
    addTripCar() {
      TripCarApi.saveTripCar(this.tripCar).then((resp) => {
        if (resp.code === '200') {
          this.tripCar = {};
          this.$successMsg(resp.msg);
          this.cancelForm();
        } else {
          this.$errorMsg(resp.msg);
        }
      });
    },
    updateTripCarById() {
      TripCarApi.updateTripCar(this.tripCar).then((resp) => {
        if (resp.code === '200') {
          this.user = {};
          this.$successMsg(resp.msg);
          this.cancelForm();
        } else {
          this.$errorMsg(resp.msg);
        }
      });
    },
    cancelForm() {
      this.close({
        path: "/saveTripCar",
      });
      this.$router.push("/tripCarList");
    },
    handleAvatarSuccess(name, res, file) {
      let data = file.response.data;
      let imageUrl = data.path;
      let preUrl = data.url;
      let form = this.$refs.form.$refs.form;
      if (name == "tripCar") {
        this.$set(this.tripCar, "driverLicenseImg", imageUrl);
        this.preImage.driverLicenseImg = preUrl;
        form.validateField("driverLicenseImg");
        this.ocrRecognizeVehicleLicense(preUrl);
      } else if (name == "driver0") {
        this.$set(this.driverList[0], "imgPath", imageUrl);
        this.preImage.imgPath[0] = preUrl;
        this.$refs.driverForm0[0].validateField("imgPath");
      } else if (name == "driver1") {
        this.$set(this.driverList[1], "imgPath", imageUrl);
        this.preImage.imgPath[1] = preUrl;
        this.$refs.driverForm1[1].validateField("imgPath");
      } else if (name == "operationCertificateImg") {
        this.$set(this.tripCar, "operationCertificateImg", imageUrl);
        this.preImage.operationCertificateImg = preUrl;
        form.validateField("operationCertificateImg");
      }
    },
    beforeAvatarUpload(file) {
      let types = ["jpeg", "jpg", "png"];
      let ext = file.name.substr(file.name.lastIndexOf('.') + 1);
      const isLt10M = file.size / 1024 / 1024 < 10;
      const isImage = types.includes(ext.toLowerCase());
      if (!isImage) {
        this.$message.error("上传头像图片只能是 jpg、png、jpeg 格式!");
      }
      if (!isLt10M) {
        this.$message.error("上传头像图片大小不能超过 10MB!");
      }
      return isImage && isLt10M;
    },
    // addDriver() {
    //   this.driverList.push(JSON.parse(JSON.stringify(this.defaultDriver)));
    // },
    // deleteDriver() {
    //   this.driverList.splice(1, 1);
    // },
    async loadMotorCadeList() {
      let resp = await TripMotorCadeApi.search({ pageSize: 1000, pageNum: 0 });
      if (resp.code === '200') {
        this.motorCadeList = resp.data.records;
        this.$nextTick(() => {
          if (this.$store.state.user.type == 'motorcade') {
            this.tripCar.motorcadeNo = this.$store.state.user.motorcadeNo;
          }
        })

      }
    },
    // queryDriverLicense() {
    //   TripDictionaryApi.queryDriverLicense().then((resp) => {
    //     if (resp.code === '200') {
    //       this.drivingType = resp.data;
    //     }
    //   });
    // },
    driverLicenseImgShow(path) {
      console.log(path);
      this.dialogImageUrl = path;
      this.dialogVisible = true;
    },
    handleRemove(type) {
      if (type == "driverLicenseImg") {
        this.tripCar.driverLicenseImg = "";
        this.preImage.driverLicenseImg = "";
      } else if (type == "operationCertificateImg") {
        this.tripCar.operationCertificateImg = "";
        this.preImage.operationCertificateImg = "";
      }
    },

    ocrRecognizeVehicleLicense(url) {
      ocrApi.recognizeVehicleLicense({ url: url })
        .then((resp) => {
          console.log(resp.data);
          if (resp.code === '200') {
            this.$set(this.tripCar, 'carNum', resp.data.carNum ?? this.tripCar.carNum);
            this.$set(this.tripCar, 'type', resp.data.carType ?? this.tripCar.type);
            this.$set(this.tripCar, 'belong', resp.data.carBelong ?? this.tripCar.belong);
            this.$set(this.tripCar, 'address', resp.data.carAddress ?? this.tripCar.address);
            this.$set(this.tripCar, 'brand', resp.data.carBrand ?? this.tripCar.brand);
            this.$set(this.tripCar, 'identificationCode', resp.data.carIdentificationCode ?? this.tripCar.identificationCode);
            this.$set(this.tripCar, 'engineCode', resp.data.carEngineCode ?? this.tripCar.engineCode);
            if (resp.data.carRegisterTime) {
              this.$set(this.tripCar, 'registerTime', new Date(resp.data.carRegisterTime).getTime());
            }

            this.$set(this.tripCar, 'maxNum', resp.data.carMaxNum ?? this.tripCar.maxNum);

          }
          else {
            this.$errorMsg(resp.msg);
          }
        })
        .catch((error) => {
          this.$errorMsg(error);
        });
    }
  },
};
</script>
  
  
<style lang="scss" scoped>
.head {
  line-height: 40px;
  font-weight: 600;
  font-size: 16px;
  margin: 0 32px 0px 32px;

  i {
    display: inline-block;
    width: 4px;
    height: 16px;
    background: #2C6AFF;
    margin-right: 8px;
    margin-bottom: -2px;
  }

  ::v-deep .el-button {
    margin: 0 8px;

  }
}

.avatar-uploader {
  .el-icon-plus {
    color: #2C6AFF;
  }
}

::v-deep .el-row {
  display: block;
  margin: 8px 32px;
}

::v-deep .el-form-item__label {
  display: block;
  text-align: left;
  width: 150px !important;
}

::v-deep .el-form-item__content {
  margin-left: 0px !important;
}

.operate {
  width: 150px;
  margin: 18px auto;
}

.uploader {
  height: 104px;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  background: #fafafa;
  border: 1px dashed #d9d9d9;
  width: 160px;
  height: 90px;
  line-height: 90px;
  text-align: center;
}

.avatar {
  width: 160px;
  height: 90px;
  display: inline-block;
  overflow: hidden;
  cursor: pointer;

  img {
    width: 100%;
    height: 100%;
  }

  span {
    width: 0;
    height: 0;
    right: 0px;
    top: 40px;
    display: inline-block;
    margin: 0;
    border-top: 40px solid red;
    border-left: 40px solid transparent;
    cursor: pointer;
    opacity: 0;
    transition: opacity ease-in-out 0.3s;

    i {
      color: white;
      position: absolute;
      left: -18px;
      top: -32px;
    }
  }
}

.avatar:hover span {
  opacity: 1;
}

::v-deep .uploader .el-form-item__label {
  width: 95px !important;
}

.avatar-uploader {
  display: inline-block;
  height: 90px;

  span {
    width: 100%;
    height: 20px;
    display: inline-block;
    position: relative;
    top: -50px;
    color: #666666 !important;
    font-size: 14px;
  }
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  background: #fafafa;
  border: 1px dashed #dddddd;
  width: 160px;
  height: 90px;
  line-height: 70px;
  text-align: center;
}


.deleteBtn {
  display: block;
  margin: 0 60px 0 auto;
  float: right;
}

::v-deep .el-input,
.el-select {
  width: 100%;
}

::v-deep .uploader .el-form-item__error {
  top: 134px;
  left: 0px;
  font-size: 12px;
}


.uploaderList {
  margin: 6px 32px 0px 32px;
  height: 145px;
  display: flex;
  justify-content: flex-start;

  .uploader {
    width: 160px;
    margin: 0 32px 0 0;
    height: 90px;
  }
}

::v-deep .el-form-item {
  margin-bottom: 4px;

  .el-form-item__label {
    color: #666666;
  }
}
</style>